body{
  margin: 0px;
  padding: 0px;
  box-sizing: border-box
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.link:hover {
  color: #4c2d1f;
}
.div {
  color: #fff;
}
.div:hover {
  color: #ce8e99;
}
@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
/*--------------------------------*/
/*------------Tabs---------------*/
.nav-tabs .nav-link {
  background-color: #fff !important;
  color: #ce8e99 !important;
}

.nav-tabs .nav-link.active {
  /* border-bottom: solid 4px #ce8e99 !important; */
  background-color: #4c2d1f !important;
  color: #fff !important;
}
/*--------------------------------*/

li {
  font-size: 16px;
  margin-left: 30px;
  list-style-type: circle;
  font-family: "PTSans-Regular";
  line-height: 30px;
}

table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td,
th {
  border: 1px solid #dddddd;
  text-align: left;
  padding: 8px;
}

tr:nth-child(even) {
  background-color: #dddddd;
}
